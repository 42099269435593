import { type MetaFunction, Outlet } from '@remix-run/react';
import React, { useState } from 'react';
import { type ExternalScriptsHandle } from 'remix-utils/external-scripts';
import { useHydrated } from 'remix-utils/use-hydrated';

import { useUserAnalytics } from '../analytics/user';
import {
  ConfirmCancelModalProvider,
  ConfirmCancelModalRoot,
} from '../components/ConfirmCancelModalContext';
import { GlobalLoading } from '../components/GlobalLoading';
import { ProvidersList } from '../components/ProvidersList';
import { AnonTrainingGenAIStarter } from '../components/Training/TrainingStarter';
import {
  useIsUserLoaded,
  UserContextProvider,
  useUser,
} from '../components/UserContext';
import { hubspotScripts } from '../tracking/hubspot';
import { isGuest } from '../types/user';
import { setAPIServiceClientSecureToken } from '../utils/setAPIClientToken';

export const handle: ExternalScriptsHandle = {
  scripts: () => {
    return [...hubspotScripts()];
  },
};

export async function clientLoader() {
  return null;
}

setAPIServiceClientSecureToken();

// User is optional.
function UserAccess(props: {
  children?: JSX.Element;
  allowGuests?: boolean;
}): JSX.Element {
  useUser({ init: true });
  const isUserLoaded = useIsUserLoaded();
  const isHydrated = useHydrated();

  if (isHydrated && !isUserLoaded) {
    return <GlobalLoading debug='user-access' />;
  }

  return props.children ? props.children : <Outlet />;
}

export function LearningStarterLayout(props: { children?: React.ReactNode }) {
  const providers = [
    <UserContextProvider useUserAnalytics={useUserAnalytics} />,
    <UserAccess />,
    <ConfirmCancelModalProvider />,
  ];

  return (
    <ProvidersList providers={providers}>
      {props.children}

      <ConfirmCancelModalRoot />
    </ProvidersList>
  );
}

export const meta: MetaFunction = () => {
  return [{ title: 'Learning | Luna Park' }];
};

function Internal() {
  const [user] = useState(useUser());
  const userIsntLoggedIn = !user.id || isGuest(user);

  return <AnonTrainingGenAIStarter userIsntLoggedIn={userIsntLoggedIn} />;
}

export function Component() {
  return (
    <LearningStarterLayout>
      <Internal />
    </LearningStarterLayout>
  );
}
